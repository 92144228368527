import { absolutePath } from "./absolutePath";

interface ChatRoomsFnProps {
  uuid?: string;
  messageId?: number;
  params?: Record<string, unknown>;
}

export const chatRooms = {
  index: ({ params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath("chat_rooms", params),
  show: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  create: ({ params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath("chat_rooms", params),
  update: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  destroy: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  currentParticipant: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/chat_room_participant`, params),
  markAllAsRead: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/mark_all_as_read`, params),
  unreadChatRooms: ({ params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath("chat_rooms/unread_chat_rooms", params),
  pinMessage: ({ uuid, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/pin_message`, params),
  spotlightMessage: ({ uuid, messageId, params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${messageId}/spotlight`, params),
  removeSpotlightMessage: ({
    uuid,
    messageId,
    params = {},
  }: ChatRoomsFnProps = {}) =>
    absolutePath(
      `chat_rooms/${uuid}/messages/${messageId}/remove_spotlight`,
      params,
    ),
  fetch: ({ params = {} }: ChatRoomsFnProps = {}) =>
    absolutePath("chat_rooms/retrieve_by_member_ids", params),
};
