import { absolutePath } from "./absolutePath";

interface ProrationFnProps {
  new_plan_id?: unknown;
  plan_coupon_id?: unknown;
}

interface FetchFnProps {
  tier: string;
  id: string;
  billing_currency: string;
  locale: string;
  plan_type: string;
}

interface FetchPlansFnProps {
  billing_cycle?: string;
  billing_currency?: string;
  locale?: string;
}

export const plans = {
  proration: ({ new_plan_id, plan_coupon_id = "" }: ProrationFnProps) =>
    absolutePath("plans/prorations", {
      new_plan_id,
      plan_coupon_id,
    }),
  fetch: ({
    tier = "",
    id = "",
    billing_currency = "",
    locale = "",
    plan_type = "",
  }: FetchFnProps) => {
    const params: Record<string, unknown> = tier ? { tier } : { id };
    if (billing_currency) {
      params.billing_currency = billing_currency;
    }
    if (locale) {
      params.locale = locale;
    }
    if (plan_type) {
      params.plan_type = plan_type;
    }
    return absolutePath("plan", params);
  },
  fetchPlans: ({
    billing_cycle,
    billing_currency,
    locale,
  }: FetchPlansFnProps = {}) => {
    const queryParams = {
      ...(billing_cycle && { billing_cycle }),
      ...(billing_currency && { billing_currency }),
      ...(locale && { locale }),
    };
    return absolutePath("plans", queryParams);
  },
};
