import { absolutePath } from "./absolutePath";

interface TopicsFnProps {
  spaceId?: string | number;
  params?: Record<string, unknown>;
}

export const topics = {
  communityMemberTopics: ({ params = {} }: TopicsFnProps) =>
    absolutePath(`topics`, params),
  spaceTopics: ({ spaceId, params = {} }: TopicsFnProps) =>
    absolutePath(`spaces/${spaceId}/topics`, params),
};
