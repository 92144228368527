import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { userLikeApi } from "@/react/api";
import { userPaths } from "@/react/helpers/urlHelpers";
import {
  useCurrentUser,
  useIsOrCanBeCommunityMember,
} from "@circle-react/contexts";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useToast } from "@circle-react-uikit/ToastV2";

export interface UseLikeClickHandlerParams {
  post: any;
  onSuccess?: ((post: any) => void) | ((post: any) => Promise<void>);
  onFailure?: (error: any) => void;
}

export const useLikeClickHandler = ({
  post,
  onSuccess,
  onFailure,
}: UseLikeClickHandlerParams) => {
  const currentUser = useCurrentUser();
  const { error } = useToast();
  const [isProcessingLike, setIsProcessingLike] = useState(false);
  const { is_liked_by_current_user } = post;
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const isIframeEmbed = useIsIframeEmbed();

  return async (event: any) => {
    event.preventDefault();
    if (!isOrCanBeCommunityMember) {
      return;
    }

    if (!currentUser) {
      error(t("log_in_to_like_posts"));

      const loginPath = userPaths.signIn({
        params: {
          post_login_redirect: window.location.href,
        },
      });

      if (isIframeEmbed) {
        window.open(loginPath, "_blank", "noreferrer");
      } else {
        window.location.href = loginPath;
      }

      return;
    }

    if (isProcessingLike) {
      return;
    }
    setIsProcessingLike(true);
    const apiParams = {
      userLikeableId: post.id,
      userLikableType: "Post",
      forReactQuery: true,
    };
    try {
      if (is_liked_by_current_user) {
        await userLikeApi.destroy(apiParams);
      } else {
        await userLikeApi.create(apiParams);
      }
    } catch (error) {
      console.error(error); // this happens during state transition when we try to create 2 likes(double click)
      // We just fetch fresh data as part of the callback.
      onFailure && onFailure(error);
    }
    if (onSuccess) {
      await onSuccess(post);
    }
    setIsProcessingLike(false);
  };
};
