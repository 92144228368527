import { absolutePath } from "./absolutePath";

type Params = Record<string, any>;

interface CollectionParams {
  params?: Params;
}

interface ItemParams {
  params?: Params;
  pageId: string | number;
}

export const sites = {
  pages: {
    index: ({ params = {} }: CollectionParams = {}) =>
      absolutePath("site/pages", params),
    create: ({ params = {} }: CollectionParams = {}) =>
      absolutePath("site/pages", params),
    destroy: ({ pageId }: { pageId: string | number }) =>
      absolutePath(`site/pages/${pageId}`),
    show: ({ params = {}, pageId }: ItemParams) =>
      absolutePath(`site/pages/${pageId}`, params),
    update: ({ params = {}, pageId }: ItemParams) =>
      absolutePath(`site/pages/${pageId}`, params),
    updateSettings: ({ params = {}, pageId }: ItemParams) =>
      absolutePath(`site/pages/${pageId}/settings`, params),
  },
};
