import { createBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { toQueryString } from "./toQueryString";

type Params = Record<string, any>;

interface SpaceGroup {
  params?: Params;
}

interface SpaceGroupId extends SpaceGroup {
  spaceGroupId?: string;
}

interface SpaceGroupSlug extends SpaceGroup {
  spaceGroupSlug?: string;
}

export const spaceGroupPaths = {
  new: ({ params = {} }: SpaceGroup) =>
    `/space_groups/new/?${toQueryString(params)}`,
  show: ({ spaceGroupSlug, params = {} }: SpaceGroupSlug) =>
    `/s/${spaceGroupSlug}/?${toQueryString(params)}`,
  members: ({ spaceGroupSlug }: SpaceGroupSlug) =>
    `/members?${toQueryString({
      filters: [
        createBaseFilter({ key: "space_groups", value: spaceGroupSlug }),
      ],
    })}`,
  invite: ({ spaceGroupSlug, params = {} }: SpaceGroupSlug) =>
    `/s/${spaceGroupSlug}/invite?${toQueryString(params)}`,
  settings: ({ spaceGroupSlug, params = {} }: SpaceGroupSlug) =>
    `/s/${spaceGroupSlug}/settings?${toQueryString(params)}`,
  toggleVisibility: ({ spaceGroupId, params = {} }: SpaceGroupId) =>
    `/space_groups/${spaceGroupId}/toggle_visibility?${toQueryString(params)}`,
  csv: ({ spaceGroupSlug, params = {} }: SpaceGroupSlug) =>
    `/s/${spaceGroupSlug}/members.csv?${toQueryString(params)}`,
};
