import { absolutePath } from "./absolutePath";

interface ChatThreadsFnProps {
  id?: string | number;
  params?: Record<string, unknown>;
}

export const chatThreads = {
  index: ({ params = {} }: ChatThreadsFnProps = {}) =>
    absolutePath(`chat_threads`, params),
  show: ({ id, params = {} }: ChatThreadsFnProps = {}) =>
    absolutePath(`chat_threads/${id}`, params),
  unreadChatThreads: ({ params = {} }: ChatThreadsFnProps = {}) =>
    absolutePath(`chat_threads/unread_chat_threads`, params),
  markAsRead: ({ id, params = {} }: ChatThreadsFnProps) =>
    absolutePath(`chat_threads/${id}/mark_as_read`, params),
};
