import { absolutePath } from "./absolutePath";

interface ChatRoomMessagesFnProps {
  uuid?: string;
  id?: string | number;
  params?: Record<string, unknown>;
}

export const chatRoomMessages = {
  index: ({ uuid, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages`, params),
  create: ({ uuid, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/`, params),
  update: ({ uuid, id, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  show: ({ uuid, id, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  delete: ({ uuid, id, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  markAsUnread: ({ uuid, id, params = {} }: ChatRoomMessagesFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}/mark_as_unread`, params),
};
