import type { UseQueryOptions } from "react-query";
import { useQuery, useQueryClient } from "react-query";
import type { CommunitySettings } from "@circle-react/types/CommunitySettings";
import type { CurrentCommunity } from "@circle-react/types/CurrentCommunity";
import type { CurrentCommunityMember } from "@circle-react/types/CurrentCommunityMember";
import type { CurrentUser } from "@circle-react/types/CurrentUser";
import type { MasqueradingEntity } from "@circle-react/types/MasqueradingEntity";
import type { ApiError } from "../config/CustomErrors";
import { reactQueryGet } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const FIVE_MIN_STALE_TIME = 5 * 60 * 1000;
const PUNDIT_USER_QUERY_KEY = "pundit-user";

type QueryFnData = void;

export interface PunditUsersObject {
  current_community_member: CurrentCommunityMember;
  current_community_settings: CommunitySettings;
  current_community: CurrentCommunity;
  current_user: CurrentUser;
  display_community_switcher: boolean;
  display_welcome_banner: boolean;
  home_page_posts_view: boolean;
  masquerading_entity: MasqueradingEntity;
  view_only_masquerading: boolean;
}

export const usePunditAPI = (
  options?: UseQueryOptions<
    QueryFnData,
    ApiError,
    PunditUsersObject | undefined
  >,
) => {
  const queryClient = useQueryClient();

  return useQuery<QueryFnData, ApiError, PunditUsersObject | undefined>(
    PUNDIT_USER_QUERY_KEY,
    () => reactQueryGet(internalApi.punditUser.show()),
    {
      notifyOnChangeProps: "tracked",
      ...options,
      onSuccess: data => {
        // Update stale time based on response
        const isCached = Boolean(
          data?.current_community_settings?.cached_pundit_api_enabled,
        );
        queryClient.setQueryDefaults(PUNDIT_USER_QUERY_KEY, {
          staleTime: isCached ? FIVE_MIN_STALE_TIME : 0,
        });

        // Call original onSuccess if provided
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );
};
