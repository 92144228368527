import { absolutePath } from "./absolutePath";

interface LayoutPartialsFnProps {
  params?: Record<string, unknown>;
}

export const layoutPartials = {
  show: ({ params = {} }: LayoutPartialsFnProps = {}) =>
    absolutePath("layout_partials", params),
};
