import { absolutePath } from "./absolutePath";

interface HomePagePostsFnProps {
  params?: Record<string, unknown>;
}

export const homePagePosts = {
  index: ({ params = {} }: HomePagePostsFnProps = {}) =>
    absolutePath("home_page_posts", params),
};
