import { absolutePath } from "./absolutePath";

interface EntityPreviewsFnProps {
  params?: Record<string, unknown>;
}

export const entityPreviews = {
  index: ({ params = {} }: EntityPreviewsFnProps = {}) =>
    absolutePath("entity_previews", params),
};
