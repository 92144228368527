import { absolutePath } from "./absolutePath";

export const contacts = {
  show: ({
    sqid,
    params = {},
  }: {
    sqid: string;
    params?: Record<string, any>;
  }) => absolutePath(`contacts/${sqid}`, params),

  // TODO-Ali: wait until we fully migrate to audience merger
  update: ({
    sqid,
    params = {},
  }: {
    sqid: number;
    params?: Record<string, any>;
  }) => absolutePath(`contacts/${sqid}`, params),
};
