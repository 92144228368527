import { absolutePath } from "./absolutePath";

interface CommunityMemberPaymentMethodFnProps {
  params?: Record<string, unknown>;
}

export const communityMemberPaymentMethod = {
  index: ({ params = {} }: CommunityMemberPaymentMethodFnProps = {}) =>
    absolutePath("community_member_payment_methods", params),
  create: ({ params = {} }: CommunityMemberPaymentMethodFnProps = {}) =>
    absolutePath("community_member_payment_methods", params),
  setupIntent: {
    create: ({ params = {} }: CommunityMemberPaymentMethodFnProps = {}) =>
      absolutePath("community_member_payment_methods/setup_intents", params),
  },
};
