import { absolutePath } from "./absolutePath";

interface BookmarksFnProps {
  id?: string | number | null;
  params?: Record<string, unknown>;
}
export const bookmarks = {
  index: ({ params = {} }: BookmarksFnProps = {}) =>
    absolutePath(`bookmarks`, params),
  create: ({ params = {} }: BookmarksFnProps = {}) =>
    absolutePath(`bookmarks`, params),
  destroy: ({ id, params = {} }: BookmarksFnProps = {}) =>
    absolutePath(`bookmarks/${id}`, params),
};
