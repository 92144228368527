import { absolutePath } from "./absolutePath";

interface MediaTranscriptFnProps {
  id?: number;
  params?: Record<string, unknown>;
}

export const mediaTranscripts = {
  show: ({ id, params = {} }: MediaTranscriptFnProps = {}) =>
    absolutePath(`media_transcripts/${id}`, params),
  update: ({ id, params = {} }: MediaTranscriptFnProps = {}) =>
    absolutePath(`media_transcripts/${id}`, params),
};
