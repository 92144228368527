import { useMemo } from "react";
import { communitySignupUrl } from "@/react/helpers/communityHelpers";
import { useReactQueryLocalStorage } from "@/react/hooks/utils/useReactQueryLocalStorage";
import { LOCALSTORAGE_KEYS } from "@circle-react/config/Settings";
import { usePunditAPI } from "@circle-react/hooks/usePunditAPI";
import { useExperimentationFlags } from "./useExperimentationFlags";

type EmptyObject = Record<string, undefined>;

export const usePunditUserContext = () => {
  const { data, refetch, isLoading } = usePunditAPI();

  const localStorageResponse = useReactQueryLocalStorage({
    key: LOCALSTORAGE_KEYS.PunditUserContext,
    data,
    defaultValue: undefined,
  });

  const emptyObject: EmptyObject = {};
  const resData = localStorageResponse.data || emptyObject;
  const currentUser = resData?.current_user;
  const currentCommunity = resData?.current_community;
  const currentCommunitySettings = resData?.current_community_settings;
  const currentCommunityMember = resData?.current_community_member;
  const shouldDisplayCommunitySwitcher = !!resData?.display_community_switcher;
  const isViewOnlyMasquerading = !!resData?.view_only_masquerading;
  const masqueradingEntity = resData?.masquerading_entity;

  const {
    flags: experimentationFlags,
    isLoading: isExperimentationFlagsLoading,
  } = useExperimentationFlags({
    communityId: currentCommunity?.id,
    currentUserId: currentUser?.id,
  });

  const memoizedExperimentationFlags = useMemo(
    () => ({
      flags: experimentationFlags,
      isLoading: isExperimentationFlagsLoading,
    }),
    [experimentationFlags, isExperimentationFlagsLoading],
  );

  return {
    refetch,
    isLoading: isLoading ? localStorageResponse.isLoading : false,
    data: resData,
    currentUser,
    currentCommunity,
    currentCommunitySettings,
    currentCommunityMember,
    displayCommunitySwitcher: shouldDisplayCommunitySwitcher,
    isViewOnlyMasquerading,
    masqueradingEntity,
    experimentation: memoizedExperimentationFlags,
  };
};

export const useCurrentCommunityMember = () =>
  usePunditUserContext().currentCommunityMember;

export const useCurrentUser = () => usePunditUserContext().currentUser;

export const useIfSignupEnabled = () => {
  const { currentCommunity, currentUser } = usePunditUserContext();

  return (callbackFn: (data: { communitySignupUrl: string }) => void) => {
    if (currentUser) return null;
    const _communitySignupUrl = communitySignupUrl(currentCommunity);

    if (_communitySignupUrl) {
      return callbackFn({ communitySignupUrl: _communitySignupUrl });
    }
  };
};

/**
 * Returns true if the current user is already a member of the community, or if
 * they can become one if the current community has public signups enabled.
 * Returns false otherwise.
 */
export const useIsOrCanBeCommunityMember = () => {
  const { currentCommunityMember, currentCommunity } = usePunditUserContext();

  return Boolean(
    currentCommunityMember ||
      currentCommunity?.allow_signups_to_public_community,
  );
};
