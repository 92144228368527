import { absolutePath } from "./absolutePath";

interface NotificationsFnProps {
  params?: Record<string, unknown>;
}

export const notifications = {
  markAllAsRead: ({ params = {} }: NotificationsFnProps) =>
    absolutePath("notifications/mark_all_as_read", params),
};
