import { absolutePath } from "../absolutePath";

interface CommunityMemberPaymentMethodsFnProps {
  params?: Record<string, unknown> | { community_member_id?: string | number };
}

export const communityMemberPaymentMethods = {
  index: ({ params = {} }: CommunityMemberPaymentMethodsFnProps = {}) =>
    absolutePath("admin/community_member_payment_methods", params),
  createPaymentMethod: () =>
    absolutePath("admin/community_member_payment_methods"),
  createSetupIntent: () =>
    absolutePath("admin/community_member_payment_methods/setup_intents"),
};
