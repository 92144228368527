import { absolutePath } from "./absolutePath";

interface SpaceMembersFnProps {
  spaceId?: string | number;
  memberId?: string | number;
  params?: Record<string, unknown>;
}

export const spaceMembers = {
  list: ({ spaceId, params = {} }: SpaceMembersFnProps) =>
    absolutePath(`spaces/${spaceId}/space_members`, params),
  show: ({ spaceId, memberId, params = {} }: SpaceMembersFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  update: ({ spaceId, memberId, params = {} }: SpaceMembersFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  sidebar: ({ spaceId, params = {} }: SpaceMembersFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/sidebar`, params),
  leaveSpace: ({ spaceId, memberId, params = {} }: SpaceMembersFnProps) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  joinSpace: ({ spaceId, params = {} }: SpaceMembersFnProps) =>
    absolutePath(`spaces/${spaceId}/space_members`, params),
};
