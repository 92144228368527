import { absolutePath } from "./absolutePath";

interface SupportWidgetFnProps {
  id?: string | number;
  spaceId?: string | number;
  searchText?: string | number;
  zendeskUploadId?: string | number;
  target?: string;
  params?: Record<string, unknown>;
  flow?: Record<string, unknown>;
}

export const supportWidget = {
  listTutorialPosts: () => absolutePath("support_widget/home"),
  listPosts: ({ spaceId, searchText }: SupportWidgetFnProps) =>
    absolutePath("support_widget/posts", {
      space_id: spaceId || "",
      search_text: searchText || "",
    }),
  listNews: () => absolutePath("support_widget/news"),
  showPost: ({ id }: SupportWidgetFnProps) =>
    absolutePath(`support_widget/posts/${id}`),
  showPinnedPost: () => absolutePath(`support_widget/home/pinned_post`),
  spaceGroups: () => absolutePath("support_widget/space_groups"),
  createConversation: () => absolutePath("support_widget/conversations"),
  listConversations: () => absolutePath("support_widget/conversations"),
  checkConversations: () => absolutePath("support_widget/conversations/check"),
  showConversation: ({ id }: SupportWidgetFnProps) =>
    absolutePath(`support_widget/conversations/${id}`),
  updateConversation: ({ id }: SupportWidgetFnProps) =>
    absolutePath(`support_widget/conversations/${id}`),
  createAttachment: () => absolutePath("support_widget/attachments"),
  checkAttachment: ({ zendeskUploadId, target }: SupportWidgetFnProps) =>
    absolutePath(`support_widget/attachments/${zendeskUploadId}/check`, {
      target: target || "",
    }),
  createPostFeedback: () => absolutePath("support_widget/post_feedbacks"),
  listEvents: () => absolutePath("support_widget/events"),
  createChatMessage: () => absolutePath("support_widget/chats"),
  createLiveChatMessage: () => absolutePath("support_widget/live_chats"),
  downloadChat: (id: SupportWidgetFnProps["id"]) =>
    absolutePath(`support_widget/chats/${id}/download`),
  completeOnboarding: () =>
    absolutePath("support_widget/onboardings/mark_as_completed"),
  uploadLiveChatAttachment: () =>
    absolutePath("support_widget/live_chats/upload"),
  getLiveChatStatus: ({ flow }: SupportWidgetFnProps = {}) =>
    absolutePath("support_widget/live_chats/status", { flow }),
};
