import { t } from "@/i18n-js/instance";
import { ApiError } from "@circle-react/config/CustomErrors";

interface Options extends RequestInit {
  headers?: Record<string, string>;
  forReactQuery?: boolean;
}

type OptionsType = Options | undefined;

interface ErrorJsonBody {
  failure?: {
    message: string;
  };
  view_only_masquerading?: boolean;
  message?: string;
  error_details?: string;
}

export const serializeJsonResponse = async (response: any) => {
  if (response.ok) {
    const text = await response.text();
    return text.length > 0 ? JSON.parse(text) : {};
  }

  const json: ErrorJsonBody = await response.json();
  const { status, statusText } = response;

  if (json?.view_only_masquerading) {
    throw new ApiError({
      message: t("view_only_error"),
      status,
      statusText,
      errorDetails: t("view_only_error"),
      body: json,
    });
  }

  const message =
    json.message || json.failure?.message || "Failed to retrieve data!";
  const { error_details = json.message } = json;

  throw new ApiError({
    message,
    status,
    statusText,
    errorDetails: error_details,
    body: json,
  });
};

export const fetchRequest = async (
  url: string,
  options: OptionsType = {},
): Promise<any> => {
  const {
    headers = {},
    forReactQuery: isForReactQuery,
    ...passThroughOptions
  } = options;

  const response = await fetch(url, {
    ...passThroughOptions,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    credentials: "same-origin",
  });

  if (!isForReactQuery) {
    return response;
  }

  return serializeJsonResponse(response);
};

export const transformToFormData = (
  data: any,
  formData = new FormData(),
  parentKey = "",
) => {
  for (const [key, value] of Object.entries<any>(data)) {
    const formattedKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof File) {
      formData.set(formattedKey, value);
    } else if (value instanceof Array) {
      value.forEach(ele => {
        formData.append(`${formattedKey}[]`, ele);
      });
    } else if (value instanceof Object) {
      transformToFormData(value, formData, formattedKey);
    } else {
      formData.set(formattedKey, value);
    }
  }
  return formData;
};

export const reactQueryGet = (url: string): Promise<any> =>
  fetchRequest(url, {
    method: "GET",
    forReactQuery: true,
  });

export const reactQueryPut = (url: string, bodyParams?: any): Promise<any> =>
  fetchRequest(url, {
    method: "PUT",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryPatch = (url: string, bodyParams?: any): Promise<any> =>
  fetchRequest(url, {
    method: "PATCH",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryPost = (url: string, bodyParams?: any): Promise<any> =>
  fetchRequest(url, {
    method: "POST",
    body: JSON.stringify(bodyParams),
    forReactQuery: true,
  });

export const reactQueryDelete = (
  url: string,
  bodyParams?: any,
): Promise<any> => {
  const options: Options = {
    method: "DELETE",
    forReactQuery: true,
  };

  if (bodyParams) {
    options.body = JSON.stringify(bodyParams);
  }

  return fetchRequest(url, options);
};
