import { absolutePath } from "./absolutePath";

interface PostDetailsFnProps {
  params?: Record<string, unknown>;
}

export const postDetails = {
  index: ({ params = {} }: PostDetailsFnProps) =>
    absolutePath("post_details", params),
};
