import { absolutePath } from "./absolutePath";

export const profileFields = {
  index: () => absolutePath("profile_fields"),
  archivedList: () => absolutePath("profile_fields/archived"),
  pageIndex: (pageName: string) =>
    absolutePath("page_profile_fields", { page_name: pageName }),
  pageFieldReorder: () => absolutePath("page_profile_fields/reorders"),
  detail: (id: string | number) => absolutePath(`profile_fields/${id}`),
  unarchive: (id: string | number) =>
    absolutePath(`profile_fields/${id}/unarchive`),
  archive: (id: string | number) =>
    absolutePath(`profile_fields/${id}/archive`),
  searchableList: () => absolutePath("searchable_profile_fields"),
};
