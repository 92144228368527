import { absolutePath } from "./absolutePath";
import { affiliates } from "./paywalls/affiliates";
import { communityMembers } from "./paywalls/communityMembers";

interface PaywallsFnProps {
  paywallId?: string | number;
  params?: Record<string, unknown> | unknown[];
}

export const paywalls = {
  index: ({ params = {} }: PaywallsFnProps = {}) =>
    absolutePath("paywalls", params),
  create: ({ params = {} }: PaywallsFnProps = {}) =>
    absolutePath("paywalls/", params),
  update: ({ paywallId, params = {} }: PaywallsFnProps = {}) =>
    absolutePath(`paywalls/${paywallId}`, params),
  delete: ({ paywallId }: PaywallsFnProps = {}) =>
    absolutePath(`paywalls/${paywallId}`),
  show: ({ paywallId, params = {} }: PaywallsFnProps = {}) =>
    absolutePath(`paywalls/${paywallId}`, params),
  communityMembers,
  updateMemberAccess: ({ paywallId, params = {} }: PaywallsFnProps) =>
    absolutePath(`paywalls/${paywallId}/update_member_access`, params),
  affiliates,
};
