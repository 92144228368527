import { accessGroups } from "./accessGroups";
import { communityMembers } from "./communityMembers";
import { spaceGroups } from "./spaceGroups";
import { spaces } from "./spaces";

export const accessRegistry = {
  communityMembers,
  spaces,
  spaceGroups,
  accessGroups,
};
