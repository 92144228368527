import { appendQueryString } from "./appendQueryString";

type Params = Record<string, any>;

interface CommunityMemberParams {
  public_uid: string;
  params?: Params;
}

export const communityMemberProfilePath = ({
  public_uid,
  params = {},
}: CommunityMemberParams) => appendQueryString(`/u/${public_uid}`, params);
