import { absolutePath } from "../../absolutePath";
import { accessGroupsMigration } from "./accessGroupsMigration";
import { affiliates } from "./affiliates";

interface PaywallsFnProps {
  id?: string | number;
  subscriptionProcessorId?: string | number;
  couponCode?: string | number;
  chargeProcessorId?: string | number;
  params?: Record<string, unknown>;
}

export const paywalls = {
  exports: {
    create: () => absolutePath("admin/paywalls/exports"),
  },
  subscriptions: {
    index: ({ params = {} }: PaywallsFnProps = {}) =>
      absolutePath("admin/paywalls/subscriptions", params),
    create: () => absolutePath("admin/paywalls/subscriptions"),
    refundOptions: ({ subscriptionProcessorId }: PaywallsFnProps = {}) =>
      absolutePath(
        `admin/paywalls/subscriptions/${subscriptionProcessorId}/refund_options`,
      ),
    cancellations: ({
      subscriptionProcessorId,
      params = {},
    }: PaywallsFnProps = {}) =>
      absolutePath(
        `admin/paywalls/subscriptions/${subscriptionProcessorId}/cancellations`,
        params,
      ),
    stats: {
      index: ({ params = {} }: PaywallsFnProps = {}) =>
        absolutePath("admin/paywalls/subscriptions/stats", params),
    },
    coupons: {
      create: ({ subscriptionProcessorId }: PaywallsFnProps = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons`,
        ),
      delete: ({ subscriptionProcessorId }: PaywallsFnProps = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons`,
        ),
      preview: ({
        subscriptionProcessorId,
        couponCode,
      }: PaywallsFnProps = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons/preview`,
          couponCode ? { coupon_code: couponCode } : {},
        ),
    },
  },
  charges: {
    index: ({ params = {} }: PaywallsFnProps = {}) =>
      absolutePath("admin/paywalls/charges", params),
    refunds: ({ chargeProcessorId, params = {} }: PaywallsFnProps = {}) =>
      absolutePath(
        `admin/paywalls/charges/${chargeProcessorId}/refunds`,
        params,
      ),
    stats: {
      index: ({ params = {} }: PaywallsFnProps = {}) =>
        absolutePath("admin/paywalls/charges/stats", params),
    },
  },
  taxSettings: {
    show: () => absolutePath("admin/paywalls/tax_settings"),
    update: () => absolutePath("admin/paywalls/tax_settings"),
  },
  paymentSettings: {
    show: () => absolutePath("admin/paywalls/payment_settings"),
    update: () => absolutePath("admin/paywalls/payment_settings"),
  },
  stats: {
    index: ({ params = {} }: PaywallsFnProps = {}) =>
      absolutePath(`admin/paywalls/stats`, params),
  },
  affiliates,
  accessGroupsMigration,
};
