import {
  fetchRequest,
  reactQueryGet,
  reactQueryPatch,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

interface ChatRoomParams {
  uuid: string;
  chat_room?: any;
}

const update = (chatRoom: ChatRoomParams, params = {}) => {
  const { uuid, chat_room } = chatRoom;
  return fetchRequest(internalApi.chatRooms.update({ uuid }), {
    method: "PATCH",
    body: JSON.stringify({ chat_room: chat_room }),
    ...params,
  });
};

const destroy = (chatRoom: ChatRoomParams, params = {}) => {
  const { uuid } = chatRoom;
  return fetchRequest(internalApi.chatRooms.destroy({ uuid }), {
    method: "DELETE",
    ...params,
  });
};

const getChatRoomCurrentParticipant = (uuid: string) =>
  reactQueryGet(internalApi.chatRooms.currentParticipant({ uuid }));

const pinMessage = (chatRoom: ChatRoomParams) => {
  const { uuid, chat_room } = chatRoom;
  return reactQueryPatch(internalApi.chatRooms.pinMessage({ uuid }), {
    chat_room: chat_room,
  });
};

const markAsUnread = (chatRoom: ChatRoomParams) => {
  const {
    uuid,
    chat_room: { message_id: id },
  } = chatRoom;
  return reactQueryPatch(
    internalApi.chatRoomMessages.markAsUnread({ uuid, id }),
  );
};

const spotlightMessage = (chatUuid: string, messageId: number) =>
  reactQueryPatch(
    internalApi.chatRooms.spotlightMessage({ uuid: chatUuid, messageId }),
  );

const removeSpotlightMessage = (chatUuid: string, messageId: number) =>
  reactQueryPatch(
    internalApi.chatRooms.removeSpotlightMessage({ uuid: chatUuid, messageId }),
  );

export const chatRoomApi = {
  update,
  destroy,
  getChatRoomCurrentParticipant,
  pinMessage,
  markAsUnread,
  spotlightMessage,
  removeSpotlightMessage,
};
