import { absolutePath } from "./absolutePath";

interface SettingsFnProps {
  params?: Record<string, unknown> | object;
}

export const site = {
  settings: {
    show: ({ params = {} }: SettingsFnProps = {}) =>
      absolutePath("site/settings", params),
    update: ({ params = {} }: SettingsFnProps = {}) =>
      absolutePath("site/settings", params),
  },
};
