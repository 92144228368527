import { absolutePath } from "./absolutePath";

interface EventsFnProps {
  spaceId?: string;
  slug?: string;
  params?: Record<string, unknown>;
}

export const events = {
  create: ({ spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events`, params),
  update: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  destroy: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  show: ({ spaceId, slug, params = {} }: EventsFnProps) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  attendees: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/event_attendees`, params),
  pin: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/pin`, params),
  duplicate: ({ spaceId, slug, params }: EventsFnProps) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/duplicate`, params),
  allEvents: ({ params }: EventsFnProps) =>
    absolutePath(`events/community_events`, params),
  addToCalendar: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/add_to_calendar`, params),
  downloadCalendar: ({ slug, spaceId, params = {} }: EventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/download_calendar`, params),
};
