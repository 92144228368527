import { absolutePath } from "./absolutePath";

interface CommunityPlanAddonsFnProps {
  addonId?: string | number;
  params?: Record<string, unknown>;
}

export const communityPlanAddons = {
  index: ({ params = {} }: CommunityPlanAddonsFnProps = {}) =>
    absolutePath("community_plan_addons", params),
  create: ({ params = {} }: CommunityPlanAddonsFnProps = {}) =>
    absolutePath("community_plan_addons", params),
  show: ({ addonId, params = {} }: CommunityPlanAddonsFnProps = {}) =>
    absolutePath(`community_plan_addons/${addonId}`, params),
};
