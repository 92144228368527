import { absolutePath } from "./absolutePath";

interface PostFollowersFnProps {
  postId?: string | number;
  postFollowerId?: string | number;
  params?: Record<string, unknown>;
}

export const postFollowers = {
  create: ({ postId, params = {} }: PostFollowersFnProps = {}) =>
    absolutePath(`posts/${postId}/post_followers`, params),
  destroy: ({
    postId,
    postFollowerId,
    params = {},
  }: PostFollowersFnProps = {}) =>
    absolutePath(`posts/${postId}/post_followers/${postFollowerId}`, params),
};
