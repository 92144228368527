import { absolutePath } from "./absolutePath";

interface GamificationFnProps {
  pointAwardId?: string | number;
  params?: Record<string, unknown>;
}

export const gamification = {
  settings: ({ params = {} }: GamificationFnProps = {}) =>
    absolutePath("/gamification/settings", params),
  members: ({ params = {} }: GamificationFnProps = {}) =>
    absolutePath("/gamification/members", params),
  leaderboard: ({ params = {} }: GamificationFnProps = {}) =>
    absolutePath("/gamification/leaderboard", params),
  pointAwards: ({ params = {} }: GamificationFnProps = {}) =>
    absolutePath("/gamification/point_awards", params),
  revokePointAward: ({ pointAwardId }: GamificationFnProps) =>
    absolutePath(`/gamification/point_awards/${pointAwardId}/undo`),
};
