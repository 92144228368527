import { absolutePath } from "./absolutePath";

export const corsAllowedOrigins = {
  get: () => absolutePath("/admin/headless/cors_allowed_origins"),
  create: () => absolutePath("/admin/headless/cors_allowed_origins"),
  update: (id: string | number) =>
    absolutePath(`/admin/headless/cors_allowed_origins/${id}`),
  delete: (id: string | number) =>
    absolutePath(`/admin/headless/cors_allowed_origins/${id}`),
};
