import { absolutePath } from "./absolutePath";

interface ManageMembersFnProps {
  params?: Record<string, unknown>;
}

export const manageMembers = {
  bulkSendInvitations: ({ params = {} }: ManageMembersFnProps = {}) =>
    absolutePath("community_members/bulk_resend_invitations", params),
};
