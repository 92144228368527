import { absolutePath } from "./absolutePath";

interface CommunityMemberSubscriptionFnProps {
  id?: string | number;
  params?: Record<string, unknown>;
}

const cancellationMutation = ({
  id,
  params = {},
}: CommunityMemberSubscriptionFnProps = {}) =>
  absolutePath(`community_member_subscriptions/${id}/cancellations`, params);

export const communityMemberSubscription = {
  index: ({ params = {} }: CommunityMemberSubscriptionFnProps = {}) =>
    absolutePath("community_member_subscriptions", params),
  cancellation: {
    create: cancellationMutation,
    destroy: cancellationMutation,
  },
};
