import { absolutePath } from "./absolutePath";

interface CommunityMemberSubscriptionPriceChangesFnProps {
  subscriptionId?: string | number;
  params?: Record<string, unknown>;
}

export const communityMemberSubscriptionPriceChanges = {
  preview: ({
    subscriptionId,
    params = {},
  }: CommunityMemberSubscriptionPriceChangesFnProps = {}) =>
    absolutePath(
      `community_member_subscriptions/${subscriptionId}/price_changes/preview`,
      params,
    ),
  create: ({
    subscriptionId,
    params = {},
  }: CommunityMemberSubscriptionPriceChangesFnProps = {}) =>
    absolutePath(
      `community_member_subscriptions/${subscriptionId}/price_changes`,
      params,
    ),
};
