import { absolutePath } from "./absolutePath";

interface RecurringEventsFnProps {
  slug?: string;
  spaceId?: string | number;
  params?: Record<string, unknown>;
}

export const recurringEvents = {
  index: ({ slug, spaceId, params = {} }: RecurringEventsFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/recurring_events`, params),
  rsvp: ({ slug, spaceId, params = {} }: RecurringEventsFnProps = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/recurring_events/rsvp`,
      params,
    ),
};
