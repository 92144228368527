import slugify from "slugify";
import { authPathsWithRedirect } from "@/react/helpers/urlHelpers";
import type { CurrentCommunity } from "@circle-react/types/CurrentCommunity";

export const PLATFORMS = Object.freeze({
  Web: "web",
  PLAY_STORE: "play_store",
  APP_STORE: "app_store",
});

export const hasFeaturedAreas = ({
  home_page_enabled,
  weekly_digest_enabled,
}: CurrentCommunity) => home_page_enabled || weekly_digest_enabled;

export const areOnBoardingStepsComplete = (community?: CurrentCommunity) => {
  if (!community) return false;
  const {
    prefs: {
      has_spaces: hasSpaces,
      has_posts: hasPosts,
      has_invited_member: hasInvitedMember,
      has_seen_widget: hasSeenWidget,
    },
  } = community;
  return hasSpaces && hasPosts && hasInvitedMember && hasSeenWidget;
};

export const isPrivateCommunity = (community?: CurrentCommunity) =>
  !!community?.is_private;

export const isPublicCommunity = (community?: CurrentCommunity) =>
  community && !isPrivateCommunity(community);

export const isTrialCommunity = (community?: CurrentCommunity) =>
  Boolean(community?.is_on_trial);

export const shouldShowSignupLink = (community?: CurrentCommunity) =>
  !!community?.show_signup_link;

export const getCommunityLogoUrl = (community?: CurrentCommunity) => {
  if (community?.logo_url) return community.logo_url;
  if (community?.icon_url) return community.icon_url;
  return "";
};

export const getCommunityLogoUrlAndType = (
  community?: CurrentCommunity,
  isDarkAppearance: boolean = false,
) => {
  const darkLogo = community?.dark_logo_url;
  const darkIcon = community?.dark_icon_url;
  const logoURL = isDarkAppearance && darkLogo ? darkLogo : community?.logo_url;
  const iconURL = isDarkAppearance && darkIcon ? darkIcon : community?.icon_url;
  const type: "logo" | "icon" = logoURL ? "logo" : "icon";

  return { url: logoURL ?? iconURL, type };
};

export const slugFromCommunityName = (name: string) =>
  slugify(name, {
    replacement: "-",
    remove: /[*+~.;()'"!:@]/g,
    lower: true,
  });

export const isPaymentProcessorEnabled = (community?: CurrentCommunity) =>
  !!community?.has_payment_processor_enabled;

// be careful when using this as it encapsulates multiple states
// as cancelled (not on grace period, incomplete, incomplete_expired)
export const isSubscriptionExpired = (community?: CurrentCommunity) =>
  Boolean(community?.subscription_cancelled);

export const isPromoCodeEnabled = (community?: CurrentCommunity) =>
  !!community?.promotion_enabled;

export const communitySignInUrl = (
  community: CurrentCommunity | undefined,
  params: object,
) => {
  try {
    const urlObj = new URL(community?.sign_in_url || "");

    Object.entries(params).forEach(([key, value]) => {
      urlObj.searchParams.append(key, value);
    });

    return urlObj.toString();
  } catch {
    return "";
  }
};

export const communitySignupUrl = (community?: CurrentCommunity) => {
  if (community?.policies?.custom_login_custom_sign_up) {
    return community.custom_sign_up_link_url;
  }
  if (community?.show_signup_link) {
    return authPathsWithRedirect.signUp();
  }
  return null;
};

export const getIsLocalStorageCacheEnabled = () =>
  window.localstorage_cache_enabled === "true";
