import { absolutePath } from "./absolutePath";

interface PollsFnProps {
  pollId?: string | number;
  pollOptionId?: string | number;
  params?: Record<string, unknown>;
}

export const polls = {
  create: () => absolutePath("polls"),
  update: (id: string | number) => absolutePath(`polls/${id}`),
  showBySgid: (sgid: string | number) => absolutePath(`polls/sgid/${sgid}`),
  respond: (pollId: string | number) =>
    absolutePath(`polls/${pollId}/poll_responses`),
  undo: (pollId: string | number) =>
    absolutePath(`polls/${pollId}/poll_responses/undo`),
  exportCsv: (pollId: string | number) =>
    absolutePath(`polls/${pollId}/poll_responses/export_csv`),
  voteList: ({ pollId, pollOptionId, params = {} }: PollsFnProps) =>
    absolutePath(
      `polls/${pollId}/poll_responses?poll_option_id=${pollOptionId}`,
      params,
    ),
};
