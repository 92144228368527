import { absolutePath } from "./absolutePath";

interface EventAttendeesFnProps {
  slug?: string;
  spaceId?: string | number;
  eventId?: string | number;
  attendeeId?: string | number;
  params?: Record<string, unknown>;
}

export const eventAttendees = {
  index: ({ slug, spaceId, params = {} }: EventAttendeesFnProps = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/event_attendees`, params),
  exportCsv: ({ slug, spaceId, params = {} }: EventAttendeesFnProps = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/export_csv`,
      params,
    ),
  searchCommunityMembers: ({
    eventId,
    params = {},
  }: EventAttendeesFnProps = {}) =>
    absolutePath(`search/events/${eventId}/community_members`, params),
  bulkRsvp: ({ slug, spaceId, params = {} }: EventAttendeesFnProps = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/bulk_rsvp`,
      params,
    ),
  destroyById: ({
    slug,
    spaceId,
    attendeeId,
    params = {},
  }: EventAttendeesFnProps = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/${attendeeId}/destroy_by_id`,
      params,
    ),
};
