import { useCallback, useRef } from "react";

export const useTotalTime = () => {
  const startTime = useRef<number | null>(null);

  const startTrackingTotalTime = useCallback(() => {
    startTime.current = Date.now();
  }, []);

  const resetTrackingTotalTime = useCallback(() => {
    // reset in this case is same as starting over
    // so we just call the start function again
    // Keeping this so that it matches other time tracking functions
    startTrackingTotalTime();
  }, [startTrackingTotalTime]);

  const getTotalTimeInSeconds = useCallback(() => {
    if (!startTime.current) {
      return 0;
    }
    return (Date.now() - startTime.current) / 1000;
  }, []);

  return {
    startTrackingTotalTime,
    resetTrackingTotalTime,
    getTotalTimeInSeconds,
  };
};
