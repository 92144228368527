import { absolutePath } from "./absolutePath";

interface CommunityMembersFnProps {
  id?: string | number;
  params?: Record<string, unknown> | object;
}

export const communityMembers = {
  show: ({ id, params = {} }: CommunityMembersFnProps = {}) =>
    absolutePath(`community_members/${id}`, params),
  list: ({ params = {} }: CommunityMembersFnProps = {}) =>
    absolutePath(`community_members`, params),
  preference: ({ id, params = {} }: CommunityMembersFnProps = {}) =>
    absolutePath(`community_members/${id}/community_member_preference`, params),
  deactivateAccount: ({ id, params = {} }: CommunityMembersFnProps = {}) =>
    absolutePath(`community_members/${id}/deactivate`, params),
  export: ({ params = {} }: CommunityMembersFnProps = {}) =>
    absolutePath(`community_members/export`, params, {
      arrayFormat: "indices",
    }),
};
