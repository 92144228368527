import { absolutePath } from "./absolutePath";

interface SpaceGroupsFnProps {
  id?: string | number;
  spaceGroupId?: string | number;
  memberId?: string | number;
  params?: Record<string, unknown>;
}

export const spaceGroups = {
  index: (params: SpaceGroupsFnProps["params"] = {}) =>
    absolutePath("space_groups", params),
  show: ({ id, params = {} }: SpaceGroupsFnProps = {}) =>
    absolutePath(`space_groups/${id}`, params),
  create: ({ params = {} }: SpaceGroupsFnProps = {}) =>
    absolutePath("space_groups", params),
  update: ({ id, params = {} }: SpaceGroupsFnProps = {}) =>
    absolutePath(`space_groups/${id}`, params),
  destroy: ({ id, params = {} }: SpaceGroupsFnProps = {}) =>
    absolutePath(`space_groups/${id}`, params),
  members: ({ spaceGroupId, params }: SpaceGroupsFnProps) =>
    absolutePath(`space_groups/${spaceGroupId}/space_group_members`, params),
  deleteMember: ({ spaceGroupId, memberId, params = {} }: SpaceGroupsFnProps) =>
    absolutePath(
      `space_groups/${spaceGroupId}/space_group_members/${memberId}`,
      params,
    ),
  exportMembers: ({ id, params = {} }: SpaceGroupsFnProps = {}) =>
    absolutePath(`space_groups/${id}/space_group_members/export`, params),
  addMemberToSpaceGroup: ({ spaceGroupId, params = {} }: SpaceGroupsFnProps) =>
    absolutePath(
      `space_groups/${spaceGroupId}/space_group_members/bulk_create`,
      params,
    ),
};
