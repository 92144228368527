import { useCallback, useEffect, useState } from "react";
import { useIdle, useInterval } from "react-use";

const UPDATE_INTERVAL_IN_MS = 1000; // 1 second
const IDLE_TIMEOUT_IN_MS = 1000 * 30; // 30 seconds

export const useScreenTime = () => {
  const [screenTimeInSeconds, setScreenTimeInSeconds] = useState(0);
  const [isTrackingScreenTime, setIsTrackingScreenTime] = useState(false);

  const isIdle = useIdle(IDLE_TIMEOUT_IN_MS);

  const [isVisible, setIsVisible] = useState(
    document.visibilityState === "visible",
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === "visible");
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Update time at regular intervals

  useInterval(() => {
    if (isTrackingScreenTime && isVisible && !isIdle) {
      setScreenTimeInSeconds(prev => prev + 1);
    }
  }, UPDATE_INTERVAL_IN_MS);

  // Start time tracking
  const startTrackingScreenTime = useCallback(() => {
    setIsTrackingScreenTime(true);
  }, []);

  // Stop time tracking
  const stopTrackingScreenTime = useCallback(() => {
    setIsTrackingScreenTime(false);
  }, []);

  // Reset the timer
  const resetTrackingScreenTime = useCallback(() => {
    setScreenTimeInSeconds(0);
  }, []);

  return {
    screenTimeInSeconds,
    isTrackingScreenTime,
    startTrackingScreenTime,
    stopTrackingScreenTime,
    resetTrackingScreenTime,
  };
};
