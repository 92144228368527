import { toQueryString } from "../toQueryString";

const path = (path: any, params: any = {}) =>
  `${path}?${toQueryString(params)}`;

export const pushNotifications = {
  list: ({ params = {} }: { params?: object } = {}) =>
    path("/internal_api/push_notification_broadcasts", params),
  create: ({ params = {} }: { params?: object } = {}) =>
    path("/internal_api/push_notification_broadcasts", params),
  show: ({ id, params = {} }: { id: number; params?: object }) =>
    path(`/internal_api/push_notification_broadcasts/${id}`, params),
  update: ({ id, params = {} }: { id: number; params?: object }) =>
    path(`/internal_api/push_notification_broadcasts/${id}`, params),
  sendNow: ({ id }: { id: number }) =>
    path(`/internal_api/push_notification_broadcasts/${id}/send_now`),
  duplicate: ({ id }: { id: number }) =>
    path(`/internal_api/push_notification_broadcasts/${id}/duplicate`),
  archive: ({ id }: { id: number }) =>
    path(`/internal_api/push_notification_broadcasts/${id}/archive`),
  deleteBroadcast: ({ id }: { id: number }) =>
    path(`/internal_api/push_notification_broadcasts/${id}`),
};
