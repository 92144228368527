import { absolutePath } from "./absolutePath";

interface EmbedsFnProps {
  params?: Record<string, unknown>;
}

export const embeds = {
  create: ({ params = {} }: EmbedsFnProps = {}) =>
    absolutePath("embeds", params),
};
