import Cookies from "js-cookie";

export const isCookieSet = (cookieName: string): boolean => {
  try {
    const cookie = Cookies.get(cookieName);
    return cookie === "true";
  } catch (error) {
    return false;
  }
};

export const isNewPlansPageCookieSet = (): boolean =>
  isCookieSet("new_plans_page_enabled");
export const isVariantBCookieSet = (): boolean =>
  isCookieSet("plans_page_variant_b_enabled");
export const isPlansPageCookieSet = (): boolean =>
  isNewPlansPageCookieSet() || isVariantBCookieSet();
export const isTrackEventLoggingCookieSet = (): boolean =>
  isCookieSet("track_event_logging_enabled");
