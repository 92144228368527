import { absolutePath } from "./absolutePath";

interface PaywallCouponsFnProps {
  paywallCouponId?: string | number;
  params?: Record<string, unknown>;
}

export const paywallCoupons = {
  index: ({ params = {} }: PaywallCouponsFnProps = {}) =>
    absolutePath("paywalls/coupons", params),
  create: ({ params = {} }: PaywallCouponsFnProps = {}) =>
    absolutePath("paywalls/coupons/", params),
  update: ({ paywallCouponId, params = {} }: PaywallCouponsFnProps = {}) =>
    absolutePath(`paywalls/coupons/${paywallCouponId}`, params),
  destroy: ({ paywallCouponId }: PaywallCouponsFnProps = {}) =>
    absolutePath(`paywalls/coupons/${paywallCouponId}`),
  calculateDiscount: ({ params = {} }: PaywallCouponsFnProps = {}) =>
    absolutePath("paywalls/coupons/calculate_discount", params),
};
