import { absolutePath } from "./absolutePath";

const onboarding = {
  get: () => absolutePath("admin/onboarding_settings"),
  upsert: () => absolutePath("admin/onboarding_settings/upsert"),
};

const welcomeSpaces = {
  get: () => absolutePath("admin/welcome_spaces"),
  put: () => absolutePath("admin/welcome_spaces"),
};

const designConfig = {
  get: () => absolutePath("admin/branded_mobile_app"),
  put: () => absolutePath("admin/branded_mobile_app"),
};

const homeTabs = {
  get: () => absolutePath("admin/home_tabs_settings"),
  upsert: () => absolutePath("admin/home_tabs_settings/upsert"),
};

export const brandedAppsBuilderUI = {
  onboarding,
  homeTabs,
  welcomeSpaces,
  designConfig,
};
