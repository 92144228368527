import { absolutePath } from "./absolutePath";

interface RichTextFieldsFnProps {
  signedId?: string | number;
  sgid?: string | number;
  params?: Record<string, unknown>;
}

export const richTextFields = {
  mentionsList: ({ params = {} }: RichTextFieldsFnProps) =>
    absolutePath("rich_text_fields/mentions", params),
  unfurlURLs: ({ params = {} }: RichTextFieldsFnProps) =>
    absolutePath("rich_text_fields/unfurl_urls", params),
  fetchAttachment: ({ signedId, params = {} }: RichTextFieldsFnProps) =>
    absolutePath(`rich_text_fields/attachments/${signedId}`, params),
  createOembed: ({ params = {} }: RichTextFieldsFnProps) =>
    absolutePath("rich_text_fields/oembeds", params),
  fetchOembed: ({ sgid, params = {} }: RichTextFieldsFnProps) =>
    absolutePath(`rich_text_fields/oembeds/${sgid}`, params),
  fetchSgidRecords: ({ params = {} }: RichTextFieldsFnProps) =>
    absolutePath("rich_text_fields/sgids", params),
};
