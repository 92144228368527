import type { AudienceType } from "@circle-react/types/AudienceList";

const memberIdString = ":memberPublicId";
const sqidString = ":sqid";

interface PathWithState {
  pathname: string;
  state?: {
    from: unknown;
  };
}

export const buildAudienceEditPath = (
  path: string,
  prevLocation: unknown,
): PathWithState => {
  const prevLocationObject = prevLocation
    ? { state: { from: prevLocation } }
    : {};

  return {
    pathname: path,
    ...prevLocationObject,
  };
};

export const getAdminAudienceEditPathsV2 = ({
  sqid = sqidString,
}: {
  sqid?: string;
}) => {
  const adminAudienceBasePath = `/admin/audience/${sqid}`;

  return {
    index: adminAudienceBasePath,
    info: `${adminAudienceBasePath}/info`,
    access: `${adminAudienceBasePath}/access`,
    billing: `${adminAudienceBasePath}/billing`,
    activityLogs: `${adminAudienceBasePath}/activity_logs`,
  };
};

/**
 * @deprecated
 * Please use the getAdminAudienceEditPathsV2 function instead.
 * This function will be removed in the future.
 */
export const getAdminAudienceEditPaths = ({
  publicId = memberIdString,
  type,
}: {
  publicId?: string;
  type: AudienceType;
}) => {
  const urlType = type === "CommunityMember" ? "members" : "contacts";
  const adminAudienceBasePath = `/admin/${urlType}/${publicId}`;

  return {
    index: adminAudienceBasePath,
    info: `${adminAudienceBasePath}/info`,
    access: `${adminAudienceBasePath}/access`,
    billing: `${adminAudienceBasePath}/billing`,
    activityLogs: `${adminAudienceBasePath}/activity_logs`,
  };
};

export const getAdminAudienceEditPathsWithStateV2 = ({
  sqid = sqidString,
  previousLocation,
}: {
  sqid?: string;
  previousLocation?: unknown;
}) => {
  const paths = getAdminAudienceEditPathsV2({ sqid });

  return {
    index: buildAudienceEditPath(paths.index, previousLocation),
    info: buildAudienceEditPath(paths.info, previousLocation),
    access: buildAudienceEditPath(paths.access, previousLocation),
    billing: buildAudienceEditPath(paths.billing, previousLocation),
    activityLogs: buildAudienceEditPath(paths.activityLogs, previousLocation),
  };
};
