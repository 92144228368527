import type { QueryClientConfig, QueryKey } from "react-query";
import { MutationCache, QueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";

interface DefaultQueryFnParams {
  queryKey: QueryKey;
}

// https://react-query.tanstack.com/guides/default-query-function
const defaultQueryFn = ({ queryKey }: DefaultQueryFnParams) => {
  if (Array.isArray(queryKey)) {
    return reactQueryGet(queryKey[0]);
  }

  if (typeof queryKey === "string") {
    return reactQueryGet(queryKey);
  }

  return undefined;
};

export const reactQueryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ["data", "error", "isLoading", "refetch"],
    },
  },
  mutationCache: new MutationCache({
    onError: (data: unknown) => {
      if (
        typeof data === "object" &&
        data !== null &&
        "body" in data &&
        typeof data.body === "object" &&
        data.body !== null &&
        "view_only_masquerading" in data.body &&
        data.body.view_only_masquerading
      ) {
        if (typeof window.onViewOnlyModeError === "function") {
          window.onViewOnlyModeError(t("view_only_error"));
        }
        throw new Error(t("view_only_error"));
      }
    },
  }),
};

export const queryClient = new QueryClient(reactQueryConfig);
