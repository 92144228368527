import { absolutePath } from "./absolutePath";

interface CourseTopicsFnProps {
  params?: Record<string, unknown>;
}

export const courseTopics = {
  index: ({ params = {} }: CourseTopicsFnProps = {}) =>
    absolutePath(`course_topics`, {
      ...params,
    }),
};
