import { absolutePath } from "./absolutePath";

interface CommunityMemberFnProps {
  memberId?: string | number;
  params?: Record<string, unknown>;
}

export const communityMember = {
  show: ({ memberId }: CommunityMemberFnProps) =>
    absolutePath(`community_members/${memberId}`),
  update: ({ memberId, params = {} }: CommunityMemberFnProps = {}) =>
    absolutePath(`community_members/${memberId}`, params),
  settings: ({ memberId, params = {} }: CommunityMemberFnProps = {}) =>
    absolutePath(`community_members/${memberId}/settings`, params),
  delete: ({ memberId }: CommunityMemberFnProps) =>
    absolutePath(`community_members/${memberId}/`),
  delete_member: ({ memberId }: CommunityMemberFnProps) =>
    absolutePath(`community_members/${memberId}/delete_member`),
  ban_member: ({ memberId }: CommunityMemberFnProps) =>
    absolutePath(`community_members/${memberId}/ban_member`),
};
