import { absolutePath } from "./absolutePath";

interface PunditUserFnProps {
  params?: Record<string, unknown>;
}

export const punditUser = {
  show: ({ params = {} }: PunditUserFnProps = {}) =>
    absolutePath("pundit_users", params),
};
