import { absolutePath } from "./absolutePath";

interface SearchFnProps {
  params?: {
    page?: number;
    query?: string;
    [key: string]: any;
  };
}

export const search = {
  communityMembers: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/community_members", params),
    /** Multiple instances of filters - AND/OR */
    indexV2: ({ params = {} }: SearchFnProps) =>
      absolutePath("v2/search/community_members", params, {
        arrayFormat: "indices",
      }),
  },
  spaces: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/spaces", params),
  },
  communities: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/communities", params),
  },
  paywalls: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/paywalls", params),
  },
  paywallsAffiliates: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/paywalls/affiliates", params),
  },
  paywallsAffiliatesCommissions: {
    index: (params: SearchFnProps["params"] = {}) =>
      absolutePath("search/paywalls/affiliates/commissions", params),
  },
  chatRooms: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/chat_rooms", params),
  },
  chatRoomMessages: {
    index: ({
      params = {},
      chat_room_uuid,
    }: {
      params: SearchFnProps["params"];
      chat_room_uuid: string;
    }) =>
      absolutePath(
        `search/chat_rooms/${chat_room_uuid}/chat_room_messages`,
        params,
      ),
  },
};

export const searchV2 = {
  basic: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/community_members.json", params),
  },
  advanced: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/advanced_search", params),
  },
  count: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/advanced_search/count.json", params),
  },
  advancedCount: {
    index: ({ params = {} }: SearchFnProps) =>
      absolutePath("search/community_members.json", params),
  },
};
