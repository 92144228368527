import { absolutePath } from "./absolutePath";

interface CustomDomainFnProps {
  params?: Record<string, unknown>;
}

export const customDomain = {
  show: ({ params = {} }: CustomDomainFnProps = {}) =>
    absolutePath("custom_domain", params),
  update: ({ params = {} }: CustomDomainFnProps = {}) =>
    absolutePath("custom_domain", params),
  destroy: ({ params = {} }: CustomDomainFnProps = {}) =>
    absolutePath("custom_domain", params),
};
