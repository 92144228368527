import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { getIsLocalStorageCacheEnabled } from "@/react/helpers/communityHelpers";
import { useLocalStorage } from "@/react/hooks/utils/useLocalStorage";

export interface UseReactQueryLocalStorageProps<TData> {
  key: string;
  data: TData | undefined;
  defaultValue: TData;
}

export interface UseReactQueryLocalStorageReturn<TData> {
  data: TData;
  isLoading: boolean;
}

export const useReactQueryLocalStorage = <TData>({
  key,
  data,
  defaultValue,
}: UseReactQueryLocalStorageProps<TData>): UseReactQueryLocalStorageReturn<TData> => {
  const { getValue, setValue } = useLocalStorage<TData>(key);
  const fromLocalStorage = useMemo(() => getValue(), [getValue]);

  useEffect(() => {
    if (!isEmpty(data) && data !== undefined) {
      setValue(data);
    }
  }, [setValue, data]);

  if (!getIsLocalStorageCacheEnabled()) {
    return {
      data: data || defaultValue,
      isLoading: true,
    };
  }

  const responseData = data || fromLocalStorage || defaultValue;

  return {
    data: responseData,
    isLoading: isEmpty(responseData),
  };
};
