import { t } from "@/i18n-js/instance";
import { getLocale } from "@circle-react/helpers/localeHelpers";

export const dateFormats = {
  long_date_short_time: "long_date_short_time",
  short_date_at_short_time: "short_date_at_short_time",
  short_time: "short_time",
  short_time_24hrs: "short_time_24hrs",
  long_date: "long_date",
  long_month_and_date: "long_month_and_date",
  short_month_and_date: "short_month_and_date",
  short_month_and_date_with_year: "short_month_and_date_with_year",
  short_date: "short_date",
  date_time_with_short_timezone: "date_time_with_short_timezone",
  date_time_with_fractional_digits_and_short_timezone:
    "date_time_with_fractional_digits_and_short_timezone",
  long_date_at_short_time: "long_date_at_short_time",
} as const;

export type DateFormat = (typeof dateFormats)[keyof typeof dateFormats];

export const formattedDateTime = ({
  dateTime,
  format,
}: {
  dateTime: Date;
  format: DateFormat;
}): string => {
  const selectedLocale = getLocale(window?.locale || "en");

  switch (format) {
    case dateFormats.long_date_short_time: // "April 13, 2022, 12:57 AM"
      return `${formattedDateTime({
        dateTime,
        format: "long_date",
      })}, ${formattedDateTime({ dateTime, format: "short_time" })}`;
    case dateFormats.short_date_at_short_time: // "Sep 12, 2022 at 10:50 AM"
      return `${formattedDateTime({
        dateTime,
        format: "short_month_and_date_with_year",
      })} ${t("time_format.at")} ${formattedDateTime({
        dateTime,
        format: "short_time",
      })}`;
    case dateFormats.long_date_at_short_time: // "April 13, 2022 at 12:57 AM"
      return `${formattedDateTime({
        dateTime,
        format: "long_date",
      })} ${t("time_format.at")} ${formattedDateTime({
        dateTime,
        format: "short_time",
      })}`;
    case dateFormats.short_time: //"12:57 AM"
      return dateTime.toLocaleTimeString(selectedLocale, {
        hour: "2-digit",
        minute: "2-digit",
      });
    case dateFormats.short_time_24hrs: //"19:57"
      return dateTime.toLocaleTimeString(selectedLocale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    case dateFormats.long_date: //"April 13, 2022"
      return dateTime.toLocaleString(selectedLocale, {
        dateStyle: "long",
      });
    case dateFormats.long_month_and_date: //"July 19"
      return dateTime.toLocaleString(selectedLocale, {
        month: "long",
        day: "2-digit",
      });
    case dateFormats.short_month_and_date: //"Jul 19"
      return dateTime.toLocaleString(selectedLocale, {
        month: "short",
        day: "2-digit",
      });
    case dateFormats.short_month_and_date_with_year: //"Jul 19, 2022"
      return dateTime.toLocaleString(selectedLocale, {
        month: "short",
        day: "2-digit",
        year: "numeric",
      });
    case dateFormats.short_date: //"5/19/20"
      return dateTime.toLocaleString(selectedLocale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    case dateFormats.date_time_with_short_timezone: //"Dec 13, 2022, 12:57 AM EST"
      return dateTime.toLocaleString(selectedLocale, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
      });
    case dateFormats.date_time_with_fractional_digits_and_short_timezone: //"Nov 30, 2023, 06:43:32.787 PM GMT-3"
      return dateTime.toLocaleString(selectedLocale, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        fractionalSecondDigits: 3,
        timeZoneName: "short",
      });
  }
};
