import { absolutePath } from "./absolutePath";

type Params = Record<string, any>;

interface Args {
  id?: string | number;
  params?: Params;
}

export interface IndexParams {
  record_id: string;
  scheduled_email_type: string;
}

export const scheduledEmails = {
  create: ({ params = {} }: Args) => absolutePath("scheduled_emails", params),
  update: ({ id, params = {} }: Args) =>
    absolutePath(`scheduled_emails/${id}`, params),
  index: (params: IndexParams) => absolutePath("scheduled_emails", params),
  destroy: ({ id, params = {} }: Args) =>
    absolutePath(`scheduled_emails/${id}`, params),
};
