import { absolutePath } from "./absolutePath";

interface NextPlanWithFeatureFnProps {
  planId?: string;
  params?: Record<string, unknown> | unknown[];
}

export const nextPlanWithFeature = {
  show: ({ planId, params = {} }: NextPlanWithFeatureFnProps = {}) =>
    absolutePath(`communities/plans/${planId}/next_plan_with_features`, params),
};
