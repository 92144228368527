import { useQuery } from "react-query";
import { experimentationFlagsApi } from "@circle-react/api/experimentationFlagsApi";
import type { ExperimentationFlags } from "../types/ExperimentationFlags";

export const EXPERIMENTATION_FLAGS_REQUEST_KEY = [
  "community",
  "experimentation-flags",
];

interface UseExperimentationFlagsProps {
  communityId?: number;
  currentUserId?: number;
}

export const useExperimentationFlags = ({
  communityId,
  currentUserId,
}: UseExperimentationFlagsProps) => {
  const { data, isLoading } = useQuery<{ flags: ExperimentationFlags }>(
    // Use a unique key for each community, and refetch if communityId changes
    [...EXPERIMENTATION_FLAGS_REQUEST_KEY, communityId],
    experimentationFlagsApi.fetch,
    {
      // Don't refetch data unless browser is refreshed
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!communityId && !!currentUserId,
    },
  );

  return {
    flags: data?.flags,
    isLoading,
  };
};
