import { absolutePath } from "./absolutePath";

interface CommunityMemberChargeFnProps {
  processorId?: string | number;
  chargeId?: string | number;
  params?: Record<string, unknown>;
}

export const communityMemberCharge = {
  show: ({ processorId }: CommunityMemberChargeFnProps) =>
    absolutePath(`community_member_charges/${processorId}`),
  index: ({ params = {} }: CommunityMemberChargeFnProps = {}) =>
    absolutePath("community_member_charges", params),
  invoice: {
    show: ({ chargeId }: CommunityMemberChargeFnProps) =>
      absolutePath(`community_member_charges/invoices/${chargeId}`),
  },
};
