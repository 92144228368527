import { useCallback, useEffect, useRef } from "react";
import type { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { analyticsTrackerApi } from "@/react/api/analyticsTrackerApi";
import { usePunditUserContext } from "@/react/contexts";
import { useDocumentHide } from "../useDocumentHide";
import { useScreenTime } from "../useScreenTime";
import { useTotalTime } from "../useTotalTime";

const BASE_URL = new URL(window.location.href).origin;

export interface NavigationTrackerProps {
  children: ReactNode;
}

export const NavigationTracker = ({ children }: NavigationTrackerProps) => {
  const { pathname, hash, search } = useLocation();
  const pageViewRefererRef = useRef(document.referrer);
  const pageExitRefererRef = useRef(document.referrer);
  const {
    masqueradingEntity,
    currentCommunity,
    currentCommunityMember,
    isLoading,
  } = usePunditUserContext();
  const screenTimeRef = useRef(0);

  const pageViewIdRef = useRef(uuidv4());
  const {
    screenTimeInSeconds,
    startTrackingScreenTime,
    resetTrackingScreenTime,
  } = useScreenTime();

  const {
    getTotalTimeInSeconds,
    startTrackingTotalTime,
    resetTrackingTotalTime,
  } = useTotalTime();

  useEffect(() => {
    screenTimeRef.current = screenTimeInSeconds;
  }, [screenTimeInSeconds]);

  const communityId = currentCommunity?.id;
  const communityMemberId = currentCommunityMember?.id;
  const shouldSkipAnalytics = !!(masqueradingEntity || isLoading);

  // Track actual time spent interacting with the page
  // Time stops when the user is idle
  // Time stops when the user is away from the page and resumes when the user is back
  // Note for video/audio content we will create a different event as user can still listen to the content.
  useEffect(() => {
    startTrackingScreenTime();
  }, [startTrackingScreenTime]);

  // tracks total time spent on the page
  useEffect(() => {
    startTrackingTotalTime();
  }, [startTrackingTotalTime]);

  const fireAnalyticsEvent = useCallback(async () => {
    if (shouldSkipAnalytics) return;

    const url = BASE_URL + pathname + search + hash;

    const properties = {
      community_id: communityId,
      community_member_id: communityMemberId,
      url,
      referer: pageViewRefererRef.current,
      page_view_id: pageViewIdRef.current,
    };
    pageViewRefererRef.current = url;
    await analyticsTrackerApi.trackPageView(properties);
  }, [
    hash,
    pathname,
    search,
    communityId,
    communityMemberId,
    shouldSkipAnalytics,
  ]);

  const firePageExitEvent = useCallback(async () => {
    if (shouldSkipAnalytics) return;

    const url = BASE_URL + pathname + search + hash;

    const properties = {
      community_id: communityId,
      community_member_id: communityMemberId,
      url,
      referer: pageExitRefererRef.current,
      time_spent_seconds: getTotalTimeInSeconds(),
      screen_time_seconds: screenTimeRef.current,
      page_view_id: pageViewIdRef.current,
    };

    pageExitRefererRef.current = url;
    resetTrackingScreenTime();
    resetTrackingTotalTime();
    await analyticsTrackerApi.trackPageExit(properties);
  }, [
    hash,
    pathname,
    search,
    communityId,
    communityMemberId,
    shouldSkipAnalytics,
    resetTrackingScreenTime,
    resetTrackingTotalTime,
    getTotalTimeInSeconds,
  ]);

  // Handle tab/window close using pagehide event
  useDocumentHide(() => void firePageExitEvent());

  // Handle route changes
  useEffect(() => {
    pageViewIdRef.current = uuidv4();
    void fireAnalyticsEvent();

    return () => {
      void firePageExitEvent();
    };
  }, [fireAnalyticsEvent, firePageExitEvent]);

  return children;
};
