import { absolutePath } from "./absolutePath";

interface LiveStreamsFnProps {
  id?: string | number;
  roomId?: string | number;
  participantId?: string | number;
  questionId?: string | number;
  params?: Record<string, unknown>;
}

export const liveStreams = {
  index: ({ params = {} }: LiveStreamsFnProps = {}) =>
    absolutePath("live_streams/rooms", params),
  liveNow: ({ params = {} }: LiveStreamsFnProps = {}) =>
    absolutePath("live_streams/rooms/live_now", params),
  create: ({ params = {} }: LiveStreamsFnProps = {}) =>
    absolutePath("live_streams/rooms", params),
  update: ({ id, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}`, params),
  show: ({ id, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}`, params),
  goLive: ({ id, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}/go_live`, params),
  join: ({ id, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}/participants`, params),
  leave: ({ id, participantId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${id}/participants/${participantId}`,
      params,
    ),
  participants: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/participants`, params),
  inviteParticipants: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/participants/invite`, params),
  prejoinToken: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/prejoin_token`,
      params,
    ),
  addCohosts: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts`, params),
  getCohosts: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts`, params),
  removeCohost: ({ id, roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts/${id}`, params),
  addModerator: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/moderators`, params),
  changeRole: ({ roomId, participantId }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/${participantId}/change_role`,
    ),
  removeParticipant: ({
    roomId,
    participantId,
    params = {},
  }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/${participantId}/remove_participant`,
      params,
    ),
  downloadParticipantsCSV: ({ roomId }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/participants/download_csv`),
  chatTranscript: ({ id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}/transcripts`),
  endStream: ({ id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}`),
  endStreamDelayed: ({ id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}/destroy_async`),
  leaveFeedback: ({ id, participantId }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${id}/participants/${participantId}/leave_feedback`,
    ),
  createRecorder: ({ id, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${id}/recorders`, params),
  configurations: ({ params = {} }: LiveStreamsFnProps = {}) =>
    absolutePath("live_streams/configurations", params),
  usage: ({ params = {} } = {}) => absolutePath("live_streams/usage", params),
  usageLimit: ({ params = {} }: LiveStreamsFnProps = {}) =>
    absolutePath("live_streams/usage/limit_info", params),
  deleteRoomRecording: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/recordings/${id}`),
  // QA Session
  updateQASession: ({ roomId }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/session`),
  // QA Questions
  getQAQuestions: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions`, params),
  createQAQuestion: ({ roomId }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions`),
  removeQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}`),
  approveQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/approve`),
  dismissQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/dismiss`),
  markQAQuestionAsAnswered: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/mark_as_answered`,
    ),
  bringBackQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/bring_back`),
  upvoteQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/upvote`),
  removeUpvoteQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/remove_upvote`,
    ),
  spotlightQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/spotlight`),
  removeSpotlightQAQuestion: ({ roomId, id }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/remove_spotlight`,
    ),
  // QA Replies
  getQAReplies: ({ roomId, questionId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies`,
      params,
    ),
  createQAReply: ({ roomId, questionId }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies`,
    ),
  pinQAReply: ({ roomId, questionId, id }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies/${id}/pin`,
    ),
  unpinQAReply: ({ roomId, questionId, id }: LiveStreamsFnProps) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies/${id}/unpin`,
    ),
  // Spotlight
  spotlightItems: ({ roomId, params = {} }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/spotlight_items`, params),
  clearSpotlightItems: ({ roomId }: LiveStreamsFnProps) =>
    absolutePath(`live_streams/rooms/${roomId}/spotlight_items/clear`),
};
