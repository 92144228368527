import { absolutePath } from "./absolutePath";

interface ChatRoomParticipantsFnProps {
  uuid?: string;
  id?: string | number;
  params?: Record<string, unknown>;
}

export const chatRoomParticipants = {
  index: ({ uuid, params = {} }: ChatRoomParticipantsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants`, params),
  create: ({ uuid, params = {} }: ChatRoomParticipantsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/`, params),
  update: ({ uuid, id, params = {} }: ChatRoomParticipantsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/${id}`, params),
  destroy: ({ uuid, id, params = {} }: ChatRoomParticipantsFnProps = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/${id}`, params),
};
