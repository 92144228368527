import type { CommunitySettings } from "@circle-react/types/CommunitySettings";
import type { CurrentCommunity } from "@circle-react/types/CurrentCommunity";
import type { CurrentCommunityMember } from "@circle-react/types/CurrentCommunityMember";

export const isCommunityAdmin = (communityMember?: CurrentCommunityMember) =>
  Boolean(communityMember?.is_admin);

export const isMemberDirectoryVisible = (
  communityMember?: CurrentCommunityMember,
  communitySetting?: CommunitySettings,
) =>
  Boolean(
    communityMember &&
      (communitySetting?.public_member_directory ||
        isCommunityAdmin(communityMember)),
  );

export const isAllEventsDirectoryVisible = (
  communityMember?: CurrentCommunityMember,
  communitySetting?: CommunitySettings,
) =>
  Boolean(
    communitySetting?.public_events_page || isCommunityAdmin(communityMember),
  );

export const isCoursesDirectoryVisible = (
  communityMember?: CurrentCommunityMember,
  currentCommunity?: CurrentCommunity,
) =>
  Boolean(
    currentCommunity?.policies?.can_view_courses_directory ||
      isCommunityAdmin(communityMember),
  );

export const isLeaderboardVisible = (currentCommunity?: CurrentCommunity) =>
  Boolean(currentCommunity?.policies?.can_view_leaderboard);

export const isCommunityModerator = (
  communityMember?: CurrentCommunityMember,
) => Boolean(communityMember?.is_moderator);

export const isCommunityModeratorButNotAdmin = (
  communityMember?: CurrentCommunityMember,
) =>
  isCommunityModerator(communityMember) && !isCommunityAdmin(communityMember);

export const isProfileConfirmed = (communityMember?: CurrentCommunityMember) =>
  Boolean(communityMember?.profile_confirmed_at);

export const isMember = (role: string) => role === "Member";

export const canCreateLive = (communityMember?: CurrentCommunityMember) =>
  Boolean(communityMember?.policies?.can_create_live);
