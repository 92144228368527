import { absolutePath } from "./absolutePath";

interface CommunitiesFnProps {
  communityId?: string;
  communitySettingId?: string;
  communityLinkId?: string;
  forceFromSubscription?: boolean;
  params?: Record<string, unknown>;
}

export const communities = {
  create: ({ params = {} }: CommunitiesFnProps = {}) =>
    absolutePath("communities", params),
  show: ({ params = {} }: CommunitiesFnProps = {}) =>
    absolutePath("communities", params),
  update: ({ params = {} }: CommunitiesFnProps = {}) =>
    absolutePath("communities", params),
  communityAiSettings: {
    show: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("community_ai_settings", params),
    languages: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("community_ai_settings/languages", params),
    update: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("community_ai_settings", params),
  },
  communitySettings: {
    index: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("community_settings", params),
    update: ({ communitySettingId, params = {} }: CommunitiesFnProps = {}) =>
      absolutePath(`community_settings/${communitySettingId}`, params),
  },
  communityLinks: {
    create: () => absolutePath("community_links"),
    update: ({ communityLinkId }: CommunitiesFnProps) =>
      absolutePath(`community_links/${communityLinkId}`),
    delete: ({ communityLinkId }: CommunitiesFnProps) =>
      absolutePath(`community_links/${communityLinkId}`),
    reorder: () => absolutePath("community_links/reorder"),
  },
  onboarding: {
    show: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("communities/onboarding", params),
    update: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("communities/onboarding", params),
  },
  onboarding_settings: {
    update: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("community_onboarding_settings", params),
  },
  customInvitationEmail: {
    show: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("communities/custom_invitation_email", params),
    update: ({ params = {} }: CommunitiesFnProps = {}) =>
      absolutePath("communities/custom_invitation_email", params),
  },
  templates: {
    index: ({ communityId, params = {} }: CommunitiesFnProps = {}) =>
      absolutePath(`communities/${communityId}/templates`, params),
    create: ({ communityId, params = {} }: CommunitiesFnProps = {}) =>
      absolutePath(`communities/${communityId}/templates`, params),
  },
  questions: {
    create: ({ communityId, params = {} }: CommunitiesFnProps = {}) =>
      absolutePath(`communities/${communityId}/questions`, params),
  },
  plans: {
    show: ({ forceFromSubscription = false }: CommunitiesFnProps) =>
      absolutePath(`communities/plans`, {
        force_from_subscription: forceFromSubscription,
      }),
  },
  experimentationFlags: {
    show: () => absolutePath(`communities/experimentation/flags`),
  },
};
