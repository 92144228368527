import { absolutePath } from "../../absolutePath";
import { paywallCheckoutAttempt } from "./attempts";

interface PaywallCheckoutFnProps {
  paywallCheckoutPath?: string;
  params?: Record<string, unknown>;
}

export const paywallCheckout = {
  preview: ({ params = {} }: PaywallCheckoutFnProps = {}) =>
    absolutePath("paywalls/checkout/preview", params),
  prepare: ({ params = {} }: PaywallCheckoutFnProps = {}) =>
    absolutePath("paywalls/checkout/prepare", params),
  create: ({ params = {} }: PaywallCheckoutFnProps = {}) =>
    absolutePath("paywalls/checkout/", params),
  complete: ({ params = {} }: PaywallCheckoutFnProps = {}) =>
    absolutePath("paywalls/checkout/complete", params),
  cancel: ({ params = {} }: PaywallCheckoutFnProps = {}) =>
    absolutePath("paywalls/checkout/cancel", params),

  attempts: paywallCheckoutAttempt,
};
