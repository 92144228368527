import type { Params } from "../toQueryString";
import { toQueryString } from "../toQueryString";

const path = (path: string, params: Params = {}) =>
  `${path}?${toQueryString(params)}`;

export const segments = {
  index: ({ params = {} } = {}) =>
    path("/internal_api/community_segments", params),
  detail: ({ id }: { id: number }) =>
    path(`/internal_api/community_segments/${id}`),
  duplicate: ({ id }: { id: number }) =>
    path(`/internal_api/community_segments/${id}/duplicate`),
  usageStatus: ({ id }: { id: number }) =>
    path(`/internal_api/community_segments/${id}/usage_status`),
  consumers: ({ id, params = {} }: { id: number; params?: Params }) =>
    path(
      `/internal_api/community_segments/${id}/community_segment_consumers`,
      params,
    ),
};
