import { absolutePath } from "./absolutePath";

interface MediaSettingsFnProps {
  id?: string | number;
  params?: Record<string, unknown>;
}

export const mediaSettings = {
  show: ({ id, params = {} }: MediaSettingsFnProps = {}) =>
    absolutePath(`media_settings/${id}`, params),
  update: ({ id, params = {} }: MediaSettingsFnProps = {}) =>
    absolutePath(`media_settings/${id}`, params),
};
