import { absolutePath } from "./absolutePath";

interface PaywallGroupFnProps {
  paywallGroupId?: string | number;
  params?: Record<string, unknown>;
}

export const paywallGroup = {
  index: ({ params = {} }: PaywallGroupFnProps = {}) =>
    absolutePath("paywalls/groups", params),
  create: ({ params = {} }: PaywallGroupFnProps = {}) =>
    absolutePath("paywalls/groups/", params),
  update: ({ paywallGroupId, params = {} }: PaywallGroupFnProps = {}) =>
    absolutePath(`paywalls/groups/${paywallGroupId}`, params),
  show: ({ paywallGroupId }: PaywallGroupFnProps) =>
    absolutePath(`paywalls/groups/${paywallGroupId}`),
};
