export const authPathsWithRedirect = {
  signIn: (postLoginRedirectUrlParam?: string) =>
    `/users/sign_in?post_login_redirect=${encodeURIComponent(
      postLoginRedirectUrlParam || window.location.href,
    )}`,
  signUp: (postLoginRedirectUrlParam?: string) =>
    `/users/sign_up?post_login_redirect=${encodeURIComponent(
      postLoginRedirectUrlParam || window.location.href,
    )}`,
};
